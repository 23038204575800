import React, { useState } from "react";
import getNotificationBanners from "../../../containers/graphql/queries/getNotificationBanners";
import { useQuery } from "@apollo/client";
import { Carousel } from "antd";

export default function NotifBanner() {
  const { loading, data } = useQuery(getNotificationBanners);
  const [isVisible, setIsVisible] = useState(true);
  if (loading) {
    return <h1>Loading...</h1>;
  }

  const banners = data?.getNotificationBanners?.data || [];

  const handleCancelClick = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      {banners.length > 0 && (
        <div
          style={{
            width: "100%",
            padding: "0",
            marginBottom: "20px"
          }}
        >
          <div>
            <Carousel autoplay dots={false}>
              {banners.map((item) => (
                <div key={item.id}>
                  <h2
                    style={{
                      position: "relative",
                      textAlign: "center",
                      backgroundColor: "rgb(236,105,124)",
                      padding: "10px",
                      bottom: "10px",
                      top: "10px",
                      fontSize: "18px"
                    }}
                  >
                    <a
                      href={item.link}
                      target="_blank"
                      style={{
                        color: "white"
                      }}
                    >
                      {item.title}
                    </a>
                  </h2>
                </div>
              ))}
            </Carousel>

            <button
              onClick={handleCancelClick}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "transparent",
                color: "white",
                border: "none",
                padding: "8px 12px",
                cursor: "pointer",
                borderRadius: "4px"
              }}
            >
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
}
