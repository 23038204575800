import React, { useState } from "react";
import getActiveBanners from "../../../containers/graphql/queries/getActiveBanners";
import { useQuery } from "@apollo/client";
import { Carousel } from "antd";
import NotifBanner from "../NotifBanner";
import ModalBanner from "../ModalBanner";

export default function Banner() {
  return (
    <div>
      <NotifBanner />
      <ModalBanner />
    </div>
  );
}
