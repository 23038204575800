export default {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  REGISTER: "/register",
  READY_SKIP: "/user/join-game",
  USER_DASHBOARD: "/user/dashboard",
  USER_HOST_GAME: "/host",
  USER_HOST_GAME_PREV: "/user/host-game",
  USER_JOIN_GAME_METHOD: "/user/join-game-method",
  USER_JOIN_GAME_WITH_SEARCH: "/user/join-game-with-search",
  USER_JOIN_GAME: "/join-game",
  USER_JOIN_GAME_PREV: "/user/join-game",
  //USER_HOW_TO_PLAY: "/user/how-to-play",
  USER_HOW_TO_PLAY: "https://playmuzingo.com/how-to-play/",
  USER_PLAY_GAME: "/user/play-game",
  USER_PLAY_FASTEST_FINGER: "/user/play-fastest-finger",
  ANONYMOUS_JOIN_GAME: "/join/lobby",
  ANONYMOUS_JOIN_GAME_PREV: "/anym/user/join-game",
  OUR_BUNDLES: "/user/our-bundles",
  SETTINGS: "/user/settings",
  PAY_FOR_PLAYLISTS: "/user/pay-for-playlists",
  USER_PLAYLIST_LANDING_PAGE: "/playlist/:playlist",
  LOBBY: "/lobby"
};
