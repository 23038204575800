import "../index.css";
import routes from "../routes";
import React, { Suspense, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Loadable from "react-loadable";
import {
  userLoading,
  userLoaded,
  authError
} from "../containers/apollo/authentication";
import { useQuery } from "@apollo/client";
import GET_USER_QUERY from "../containers/graphql/queries/getUser";
// import OurBundles from "./pages/OurBundles";

import { Loading } from "./layouts/Spinner";
import PrivateRoute from "./common/PrivateRoute";
import showErrorMessage from "../utilities/showErrorMessage";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { onUserAnalytics, onPageViewAnalytics } from "../utilities/analytics";

const Overlay = ({ children }) => {
  return (
    <Fragment>
      <div className="body-overlay"></div>
      <div className="main-body">
        <div style={{ position: "relative", zIndex: 5 }}>{children} </div>
      </div>
    </Fragment>
  );
};

const Login = Loadable({
  loader: () => import("./pages/Login"),
  loading: () => <Loading />
});

const Register = Loadable({
  loader: () => import("./pages/Register"),
  loading: () => <Loading />
});

const ForgotPassword = Loadable({
  loader: () => import("./pages/ForgotPassword"),
  loading: () => <Loading />
});

const UserDashboard = Loadable({
  loader: () => import("./pages/Dashboard"),
  loading: () => <Loading />
});

const HowToPlay = Loadable({
  loader: () => import("./pages/HowToPlay"),
  loading: () => <Loading />
});

const HostGame = Loadable({
  loader: () => import("./pages/HostGame"),
  loading: () => <Loading />
});

const PlayGame = Loadable({
  loader: () => import("./pages/GameBoard"),
  loading: () => <Loading />
});

const PlayFastestFinger = Loadable({
  loader: () => import("./pages/GameBoard/index-fastestFinger"),
  loading: () => <Loading />
});

const JoinGame = Loadable({
  loader: () => import("./pages/JoinGame"),
  loading: () => <Loading />
});

const JoinGameRedirect = Loadable({
  loader: () => import("./pages/JoinGameRedirect"),
  loading: () => <Loading />
});

const JoinGameRedirectWithParams = Loadable({
  loader: () => import("./pages/JoinGameRedirectWithParams"),
  loading: () => <Loading />
});

const PrevJoinGame = Loadable({
  loader: () => import("./pages/PrevJoinGame"),
  loading: () => <Loading />
});

const PrevHostGame = Loadable({
  loader: () => import("./pages/PrevHostGame"),
  loading: () => <Loading />
});

const JoinGameMethod = Loadable({
  loader: () => import("./pages/JoinGameMethod"),
  loading: () => <Loading />
});

const JoinGameWithSearch = Loadable({
  loader: () => import("./pages/JoinGameWithSearch"),
  loading: () => <Loading />
});

const UnauthenticatedJoin = Loadable({
  loader: () => import("./pages/UnauthenticatedJoin"),
  loading: () => <Loading />
});
const OurBundles = Loadable({
  loader: () => import("./pages/OurBundles"),
  loading: () => <Loading />
});

const Settings = Loadable({
  loader: () => import("./pages/Settings"),
  loading: () => <Loading />
});
const PayForPlaylists = Loadable({
  loader: () => import("./resources/HostGameStepOne/PayForPlaylist"),
  loading: () => <Loading />
});
const PlaylistLandingPage = Loadable({
  loader: () => import("./resources/Playlists/PlaylistLandingPage"),
  loading: () => <Loading />
});

export default function App() {
  const { loading, error, data } = useQuery(GET_USER_QUERY);

  onPageViewAnalytics();

  if (loading) {
    userLoading();
    return <Loading />;
  } else {
    if (data) {
      let authenticatedUserData = data?.getUser;
      userLoaded({ authenticatedUserData });
      onUserAnalytics(authenticatedUserData);
    }
    if (error) {
      authError();
      //showErrorMessage(error.message);
    }
  }

  return (
    <HelmetProvider>
      <Overlay>
        <Router>
          <Toaster position="top-right" reverseOrder={false} />
          <Suspense fallback={Loading}>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path={routes.LOGIN} component={Login} />
              <Route exact path={routes.REGISTER} component={Register} />
              <Route
                exact
                path={routes.FORGOT_PASSWORD}
                component={ForgotPassword}
              />

              <Route
                exact
                path={`${routes.ANONYMOUS_JOIN_GAME}/:id`}
                component={UnauthenticatedJoin}
              />

              <Route
                exact
                path={`${routes.ANONYMOUS_JOIN_GAME_PREV}`}
                component={JoinGameRedirect}
              />

              <Route
                exact
                path={`${routes.ANONYMOUS_JOIN_GAME_PREV}/:id`}
                component={JoinGameRedirectWithParams}
              />

              <Route
                exact
                path={routes.USER_PLAYLIST_LANDING_PAGE}
                component={PlaylistLandingPage}
              />
              <PrivateRoute
                exact
                path={routes.USER_DASHBOARD}
                component={UserDashboard}
              />
              <PrivateRoute
                exact
                path={routes.OUR_BUNDLES}
                component={OurBundles}
              />
              <PrivateRoute
                exact
                path={routes.PAY_FOR_PLAYLISTS}
                component={PayForPlaylists}
              />
              <PrivateRoute exact path={routes.SETTINGS} component={Settings} />
              <PrivateRoute
                exact
                path={routes.USER_HOW_TO_PLAY}
                component={HowToPlay}
              />
              <PrivateRoute
                exact
                path={routes.USER_HOST_GAME}
                component={HostGame}
              />
              <PrivateRoute
                exact
                path={routes.USER_HOST_GAME_PREV}
                component={PrevHostGame}
              />
              <PrivateRoute
                exact
                path={`${routes.USER_HOST_GAME}/:id`}
                component={HostGame}
              />
              <PrivateRoute
                exact
                path={routes.USER_PLAY_GAME}
                component={PlayGame}
              />
              <PrivateRoute
                exact
                path={routes.USER_PLAY_FASTEST_FINGER}
                component={PlayFastestFinger}
              />
              <PrivateRoute
                exact
                path={`${routes.LOBBY}/:id`}
                component={JoinGame}
              />
              <PrivateRoute
                exact
                path={routes.USER_JOIN_GAME}
                component={JoinGame}
              />
              <PrivateRoute
                exact
                path={`${routes.USER_JOIN_GAME_PREV}`}
                component={PrevJoinGame}
              />
              <PrivateRoute
                exact
                path={routes.USER_JOIN_GAME_METHOD}
                component={JoinGameMethod}
              />
              <PrivateRoute
                exact
                path={routes.USER_JOIN_GAME_WITH_SEARCH}
                component={JoinGameWithSearch}
              />
              {/* <PrivateRoute
              exact
              path={routes.USER_PLAYLIST_LANDING_PAGE}
              component={PlaylistLandingPage}
            /> */}
            </Switch>
          </Suspense>
        </Router>
      </Overlay>
    </HelmetProvider>
    // </GrowthBookProvider>
  );
}
