import React, { useState } from "react";
import getModalBanners from "../../../containers/graphql/queries/getModalBanners";
import { useQuery } from "@apollo/client";
import { Carousel } from "antd";

export default function ModalBanner() {
  const { loading, data } = useQuery(getModalBanners);

  const [isModalVisible, setModalVisible] = useState(true);

  const handleCloseBanner = () => {
    setModalVisible(false);
  };

  const banners = data?.getModalBanners?.data || [];

  const handleBackdropClick = (e) => {
    if (e.target.id === "backdrop") {
      handleCloseBanner();
    }
  };

  if (!isModalVisible || banners.length === 0) return null;

  return (
    <>
      <style>
        {`
                    .slick-dots {
                        bottom: 0 !important; 
                    }
                `}
      </style>
      <div
        id="backdrop"
        onClick={handleBackdropClick}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000
        }}
      >
        <div
          style={{
            position: "relative",
            width: "80%",
            maxWidth: "800px",
            backgroundColor: "transparent"
          }}
        >
          <button
            onClick={handleCloseBanner}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              borderRadius: "10px",
              padding: "10px",
              cursor: "pointer",
              fontSize: "1.5rem",
              backgroundColor: "rgb(236,105,124)",
              color: "white",
              zIndex: 2000
            }}
          >
            X
          </button>

          <div style={{ position: "relative" }}>
            <Carousel autoplay>
              {banners.map((item) => (
                <div
                  key={item.id}
                  style={{ position: "relative", textAlign: "center" }}
                >
                  <div style={{ position: "relative" }}>
                    <img
                      src={item.image}
                      alt={item.title}
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        objectFit: "cover"
                      }}
                    />

                    <div
                      style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        width: "100%",
                        color: "white",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        padding: "20px",
                        borderRadius: "0 0 8px 8px",
                        textAlign: "center",
                        zIndex: 1500
                      }}
                    >
                      <h2 style={{ margin: "0 0 20px 0" }}>{item.title}</h2>
                      <p style={{ margin: "0 0 20px 0" }}>{item.description}</p>
                      <a
                        href={item.link}
                        style={{
                          backgroundColor: "rgb(236,105,124)",
                          color: "white",
                          padding: "10px",
                          fontWeight: "bold",
                          textDecoration: "none",
                          borderRadius: "10px",
                          marginBottom: "10px"
                        }}
                      >
                        Join Game
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
}
